import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@common-ui';
import { JourneyService } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CommonApiService } from '../../services/common-api.service';

@Component({
  selector: 'app-export-logs-modal',
  templateUrl: './export-logs-modal.component.html',
  styleUrls: ['./export-logs-modal.component.scss'],
})
export class ExportLogsModalComponent {
  @Input() exportFilter: any;
  @Input() exportData: 'logs' | 'auditLogs' = 'logs';
  @ViewChild('exportForm', { static: true }) exportForm: NgForm;

  exportSubscription: Subscription;
  storageInfo: any = {};
  uploadedFileData: FormData;
  uploadedFileName: string;

  constructor(
    private commonApiService: CommonApiService,
    public activeModal: NgbActiveModal,
    private journeyService: JourneyService,
    private notificationService: NotificationService
  ) {}

  uploadServiceAccount(target: EventTarget) {
    const files = (target as HTMLInputElement).files;
    if (!files?.[0]) return;
    this.uploadedFileName = files[0].name;
    const data = new FormData();
    data.append('serviceAccount', files[0], files[0].name);
    this.uploadedFileData = data;
  }

  export() {
    if (this.exportForm.invalid) return;

    const params = { ...this.exportFilter, ...this.storageInfo, journeyApiKey: this.journeyService.journey.apiKey };

    if (this.exportData === 'logs') {
      this.exportSubscription = this.commonApiService.exportLogsOnStorage(this.uploadedFileData, params).subscribe(() => {
        this.notificationService.showToast('The export has been completed successfully, the logs are been loaded on your storage.', {
          type: 'success',
        });

        this.activeModal.close();
      });
    } else {
      this.exportSubscription = this.commonApiService.exportAuditLogsOnStorage(this.uploadedFileData, params).subscribe(() => {
        this.notificationService.showToast('The export has been completed successfully, the audit logs are been loaded on your storage.', {
          type: 'success',
        });

        this.activeModal.close();
      });
    }
  }
}
