<div class="box_wrapper">
    <div class="dashboard_chunk">
        <h6 class="chunk_title">Last Week</h6>
        <div class="chunk_body flex-grow-1 my-3">
            <div class="head_chart h-100">
                <ng-template [spinnerLoader]="subscriptions['ConversationsStatistics']?.closed"></ng-template>
                <line-chart [labels]="chartLabels" [dataset]="chartDataset"> </line-chart>
            </div>
        </div>
    </div>
</div>