import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  interceptHandlers: Array<string> = [`${this.env.API.orchestrator_configurator}/personality/result`];

  constructor(private translateService: TranslateService, @Inject(ENVIRONMENT) private env: Environment) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.interceptHandlers.indexOf(req.url) !== -1 && !req.params.has('lang')) {
      return next.handle(
        req.clone({
          params: req.params.set('lang', this.translateService.currentLang || this.translateService.getDefaultLang()),
        })
      );
    }

    return next.handle(req);
  }
}
