import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import Chart from 'chart.js/auto';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'circle-progress-bar',
  templateUrl: './circle-progress-bar.component.html',
  styleUrls: ['./circle-progress-bar.component.scss'],
})
export class CircleProgressBarComponent implements AfterViewInit {
  _remaining: number;
  get remaining(): number {
    return this._remaining;
  }
  @Input() set remaining(remaining: number) {
    this._remaining = remaining;
    if (this.chartRef) {
      this.updateChart();
    }
  }

  @Input() total: number;
  @Input() mainTitle: string;
  @Input() unit: string;
  @Input() tooltips: { title: string; labels: string[] }[];

  @ViewChild('doughnutChartWrapper') doughnut: ElementRef;
  chartRef;

  constructor(private decimalPipe: DecimalPipe) {}
  ngAfterViewInit() {
    this.chartRef = new Chart(this.doughnut.nativeElement, {
      type: 'doughnut',
      data: {
        datasets: [{ backgroundColor: [this.getFillerColor, '#dee2e6'], data: this.processData() }],
      },
      plugins: [this.centerTerxt],
      options: {
        borderWidth: 0,
        circumference: 270,
        rotation: 225,
        responsive: true,
        maintainAspectRatio: false,
        radius: '90%',
        cutout: '80%',
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            enabled: this.tooltips,
            displayColors: false,
            backgroundColor: 'rgba(255, 255, 255)',
            bodyColor: 'rgba(0, 0, 0)',
            titleColor: 'rgba(0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0.8)',
            borderWidth: 0.5,
            callbacks: {
              title: (context) => this.tooltips && this.tooltips[context[0].dataIndex].title,
              label: (context) => this.tooltips && this.tooltips[context.dataIndex].labels,
            },
          },
        },
      },
    });
  }

  ngOnDestroy() {
    if (this.chartRef instanceof Chart) this.chartRef.destroy();
  }
  updateChart() {
    this.chartRef.data.labels = this.tooltips;
    this.chartRef.data.datasets = [{ backgroundColor: [this.getFillerColor, '#dee2e6'], data: this.processData() }];
    this.chartRef.options.plugins.tooltip.enabled = this.tooltips;
    this.chartRef.update();
  }

  private processData() {
    const val1 = Math.max(0, this.remaining);
    const val2 = Math.max(0, this.total - val1);
    return [val1, val2];
  }
  get getFillerColor(): string {
    const lowRange = (this.total / 100) * 33;
    const midRange = lowRange * 2;
    if (this.remaining <= lowRange) return '#dc3545';
    else if (this.remaining > lowRange && this.remaining <= midRange) return '#ffc107';
    else return '#28a745';
  }

  centerTerxt = {
    id: 'centerText',
    afterDatasetsDraw: (chart) => {
      const {
        ctx,
        chartArea: { top, width, height },
      } = chart;
      const middleWidth = width / 2;
      let startingHeight = height / 2 + top;

      ctx.save();

      let fontSize = 24;
      let verticalSpacing = 5;
      ctx.font = `${fontSize}px Poppins, sans-serif`;
      ctx.fillStyle = 'rgb(33, 37, 41)';
      ctx.textAlign = 'center';
      ctx.fillText(`${this.decimalPipe.transform(this.remaining)}`, middleWidth, startingHeight);

      startingHeight += fontSize + verticalSpacing;

      fontSize = 14;
      verticalSpacing = 2;
      ctx.font = `${fontSize}px Poppins, sans-serif`;
      ctx.fillText(`of ${this.decimalPipe.transform(this.total)}`, middleWidth, startingHeight);
      startingHeight += fontSize + verticalSpacing;
      ctx.fillText(`${this.unit}`, middleWidth, startingHeight);

      fontSize = 18;
      verticalSpacing = 6;

      startingHeight = height - (fontSize + verticalSpacing) * 2;

      ctx.font = `${fontSize}px Poppins, sans-serif`;

      const words = this.mainTitle.split(' ');

      let row = '';
      let rowIndex = 0;
      for (const word of words) {
        if (ctx.measureText(`${row} ${word}`).width < middleWidth) {
          row += ` ${word}`;
        } else {
          ctx.fillText(row, middleWidth, startingHeight + (fontSize + verticalSpacing) * rowIndex);
          row = word;
          rowIndex++;
        }
      }
      ctx.fillText(row, middleWidth, startingHeight + (fontSize + verticalSpacing) * rowIndex);
      ctx.restore();
    },
  };
}
