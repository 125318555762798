import { Role } from "./role.model";

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    resetKey?: any;
    enabled: boolean;
    lang: string;
    accountId: number;
    verified: boolean;
    roles: Role[];
    permissions: string[];
    fullName: string;
}
