import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NotificationService } from '@common-ui';
import { Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingGuard implements CanActivate {
  navigationStartSubscription: Subscription;
  navigationEndSubscription: Subscription;

  constructor(private notificationService: NotificationService, private router: Router) {}
  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.navigationStartSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        first()
      )
      .subscribe(() => {
        this.notificationService.showModalSpinner();
      });

    this.navigationEndSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        first()
      )
      .subscribe(() => {
        this.notificationService.hideModalSpinner();
      });

    return true;
  }
}
