<ng-container [formGroup]="intentForm">
    <div id="newTrainngPhrase">
        <label class="form-label">Training Phrases</label>

        <form #inputForm="ngForm" (ngSubmit)="addTrainingPhrase()">
            <div class="list_input">
                <input name="trainingPhrase" type="text" class="form-control" placeholder="Enter training phrases..." required [(ngModel)]="inputPhrase" #trainingPhrase="ngModel" (keydown.enter)="inputForm.onSubmit($event)" />
                <button type="submit" class="btn text_design add_item">+ Add</button>

                <div class="input_validation" *ngIf="trainingPhrase.errors?.required && inputForm.submitted">Training Phrase is required</div>
            </div>
        </form>

        <div class="dynamic_field" *ngFor="let phrase of trainingPhrases.controls | slice: pagination.getFirstItemIndex():pagination.getLastItemIndex(); let idxPrhase = index">
            <div class="entity_pop_toggle" [ngbPopover]="popEntityContent" triggers="manual" #popEntity="ngbPopover" [popoverClass]="'popover_entity'" [placement]="['top', 'auto']" container="body" [autoClose]="'outside'" [ngStyle]="{ left: entityPopOffset }"></div>

            <div
                contenteditable="true"
                class="form-control field_prahase"
                [phraseParts]="phrase"
                [parameters]="entityParameters"
                (entitySelected)="togglePopEntity($event, idxPrhase + pagination.getFirstItemIndex())"
                [updateEntities]="onUpdateEntities[idxPrhase + pagination.getFirstItemIndex()]"
            ></div>

            <button type="button" class="btn field_remove" (click)="removeTrainingPhrases(idxPrhase + pagination.getFirstItemIndex())"><i class="fal fa-times-circle" aria-hidden="true"></i></button>
        </div>
        <div class="row mb-3" *ngIf="pagination.totalItems > pagination.pageSize">
            <div class="col-12">
                <pagination [paginationData]="pagination" (paginationCallback)="selectPage($event)"></pagination>
            </div>
        </div>
    </div>

    <table class="table entity_parameters design_layout" *ngIf="parametersManagement" aria-describedby="Training Parameters Table">
        <thead>
            <tr>
                <th scope="col">Required</th>
                <th scope="col">Parameter Name</th>
                <th scope="col">Entity</th>
                <th scope="col">Value</th>
                <th scope="col">Prompt</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody formArrayName="parameters">
            <tr *ngFor="let parameter of entityParameters.controls; index as i" [formGroupName]="i">
                <td class="text-center">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="requiredEntity_{{ i }}" formControlName="required" />
                        <label class="form-check-label" for="requiredEntity_{{ i }}"></label>
                    </div>
                </td>
                <td>
                    <input type="text" name="name" class="form-control" placeholder="name..." formControlName="name" required [ngClass]="{ 'is-invalid': parameter.get('name').invalid && intentSubmitted }" />
                    <div class="invalid-feedback" *ngIf="parameter.get('name').errors?.required">Name is required</div>
                    <div class="invalid-feedback" *ngIf="parameter.get('name').errors?.duplicateName">Name must be unique</div>
                </td>
                <td>
                    <div class="entity_pop_toggle" [ngbPopover]="popParameterEntityContent" triggers="manual" #popParameterEntity="ngbPopover" [popoverClass]="'popover_entity'" [placement]="'top'" container="body" [autoClose]="'outside'"></div>

                    <a href="javascript:void(0)" *ngIf="!parameter.get('entityType').value" (click)="togglePopParameterEntity(i, parameter)">@Entitiy</a>

                    <span class="parameter_name matches_{{ i }}" [ngClass]="{ 'is-invalid': parameter.get('entityType').invalid && intentSubmitted }">
                        {{ parameter.get('entityType').value }}
                    </span>
                    <div class="invalid-feedback" *ngIf="parameter.get('entityType').errors?.required">Select an entity</div>
                </td>
                <td>
                    <input type="text" name="value" class="form-control" placeholder="value..." formControlName="value" [ngClass]="{ 'is-invalid': parameter.get('value').invalid && intentSubmitted }" />
                    <div class="invalid-feedback" *ngIf="parameter.get('value').errors?.required">Value is required</div>
                </td>
                <td formArrayName="prompts" class="prompts_col" (click)="editPrompt(i); $event.stopPropagation()">
                    <ng-container *ngIf="parameter.value['required']">
                        <span *ngIf="getParameterPrompts(i).length > 0">
                            {{ getParameterPrompts(i).value }}
                        </span>
                        <span class="text-muted" *ngIf="getParameterPrompts(i).length === 0"> Enter prompts... </span>
                        <div class="invalid-feedback" [ngClass]="{ 'd-block': entityParameters.at(i).errors?.promptsRequired && intentSubmitted }">Prompt is required</div>
                    </ng-container>
                </td>
                <td class="action_column_sm">
                    <button class="btn text-muted" (click)="entityParameters.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                </td>
            </tr>
            <tr>
                <td colspan="6">
                    <button class="btn p-0 text_design" (click)="addParameter()">
                        <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>

<ng-template #popEntityContent let-popSelection="$event">
    <ng-template [spinnerLoader]="subscriptions['Entities'].closed" [size]="'2x'"></ng-template>

    <div role="alert" class="alert alert-warning mb-0" *ngIf="!patchedEntities.length && subscriptions['Entities'].closed">No entity for this Intent</div>

    <form novalidate *ngIf="patchedEntities.length && subscriptions['Entities'].closed">
        <div class="mb-3 entity_head" *ngIf="popSelection.entityType">
            <span class="current_entity matches_{{ popSelection.aliasIndex }}">{{ popSelection.entityType }}</span>
            <button type="button" class="btn remove_entity" (click)="setEntity(null, popSelection)"><i class="fal fa-trash-alt" aria-hidden="true"></i></button>
        </div>
        <div>
            <input type="text" name="entityFitler" class="form-control form-control-sm" placeholder="Search entity..." [(ngModel)]="textFilter" #ctrl="ngModel" />
        </div>
    </form>

    <ul class="list-unstyled entities_list design_layout" *ngIf="patchedEntities.length && subscriptions['Entities'].closed">
        <li>
            <a href="#" [routerLink]="['/entities']"><i class="fas fa-plus" aria-hidden="true"></i> Add Entity </a>
        </li>
        <li *ngFor="let entity of patchedEntities | fullTextSearch: textFilter:'entityType'" [hidden]="entity.name && entity.name === popSelection.alias">
            <a href="javascript:void(0)" (click)="setEntity(entity, popSelection)">
                <span>{{ entity.entityType }}</span
                ><span *ngIf="entity.name">: {{ entity.name }}</span>
            </a>
        </li>
    </ul>
</ng-template>

<ng-template #popParameterEntityContent let-parameterSelection="parameter">
    <ng-template [spinnerLoader]="subscriptions['Entities'].closed" [size]="'2x'"></ng-template>
    <form #searchIntentForm="ngForm" novalidate>
        <input type="text" name="entityFilter" class="form-control" placeholder="Search entity..." [(ngModel)]="textFilter" #ctrl="ngModel" />
    </form>

    <ul class="list-unstyled entities_list design_layout">
        <li>
            <a href="#" [routerLink]="['/entities']"><i class="fas fa-plus" aria-hidden="true"></i> Add Entity </a>
        </li>
        <li *ngFor="let entity of entities | fullTextSearch: textFilter">
            <a href="javascript:void(0)" (click)="updateEntityParameter(entity, parameterSelection)">{{ entity }}</a>
        </li>
    </ul>
</ng-template>
