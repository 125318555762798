import { Component, Input } from '@angular/core';

@Component({
  selector: 'component-status-card',
  templateUrl: './component-status-card.component.html',
  styleUrls: ['./component-status-card.component.scss'],
})
export class ComponentStatusCardComponent {
  @Input() status: string;
  @Input() name: string;
}
