import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session-expired-modal',
  templateUrl: './session-expired-modal.component.html',
  styleUrls: ['./session-expired-modal.component.scss'],
})
export class SessionExpiredModalComponent implements OnInit {
  sessionTimeout: number;
  sessionExpired = false;
  remainingTime: number;
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    const intervalRef = setInterval(() => {
      this.remainingTime = this.sessionTimeout - new Date().getTime();
      if (this.remainingTime < 1000) {
        this.sessionExpired = true;
        clearInterval(intervalRef);
      }
    }, 1000);
  }
  close() {
    this.activeModal.close();
  }
}
