import { HttpClientTestingModule } from '@angular/common/http/testing';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterTestingModule } from '@angular/router/testing';
import { CommonUiModule } from '@common-ui';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CodeEditorService } from '../services/code-editor.service';
import { CommonApiService } from '../services/common-api.service';
import { TEST_CODE_EDITOR_SERVICE, TEST_COMMON_API_SERVICE } from './services.mock';

@NgModule({
  imports: [HttpClientTestingModule, RouterTestingModule, FormsModule, ReactiveFormsModule, NgbModule, CommonUiModule],
  providers: [
    NgbActiveModal,
    { provide: CodeEditorService, useValue: TEST_CODE_EDITOR_SERVICE },
    { provide: CommonApiService, useValue: TEST_COMMON_API_SERVICE },
  ],
  exports: [HttpClientTestingModule, RouterTestingModule, FormsModule, ReactiveFormsModule, NgbModule],
})
export class SharedModuleMocked {}
