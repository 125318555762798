import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';
import { ComponentStatusCardComponent } from './components/component-status-card/component-status-card.component';
import { HealthStatusModalComponent } from './components/health-status-modal/health-status-modal.component';
import { HealthStatusComponent } from './components/health-status/health-status.component';
import { LivepersonAgentStatusCardComponent } from './components/liveperson-agent-status-card/liveperson-agent-status-card.component';
import { LogsComponent } from './components/logs/logs.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { MonitoringRoutingModule } from './monitoring-routing.module';
import { MonitoringService } from './services/monitoring.service';



@NgModule({
  declarations: [
    MonitoringComponent,
    HealthStatusComponent,
    HealthStatusModalComponent,
    ComponentStatusCardComponent,
    LogsComponent,
    LivepersonAgentStatusCardComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MonitoringRoutingModule
  ],
  providers: [MonitoringService]
})
export class MonitoringModule { }
