
<div class="row">
    <div class="col-12 mb-3">
        <h5>User Informations</h5>
    </div>

    <dl class="col-6 user_info">
        <dt>First name</dt>
        <dd>{{user.firstName}}</dd>
    </dl>
    <dl class="col-6 user_info">
        <dt>Last name</dt>
        <dd>{{user.lastName}}</dd>
    </dl>
    <dl class="col-6 user_info">
        <dt>Email</dt>
        <dd>{{user.email}}</dd>
    </dl>
    <dl class="col-6 user_info">
        <dt>Role</dt>
        <dd>{{roles}}</dd>
    </dl>
    <div class="col-auto mb-3">
        <a href="javascript:void(0)" (click)="changePassword()">Reset Password</a>
    </div>
</div>
