import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
// import { environment } from '@environments/environment';
import { OAuthModule } from 'angular-oauth2-oidc';
import { LoginComponent } from './components/login/login.component';
import { SessionExpiredModalComponent } from './components/session-expired-modal/session-expired-modal.component';
import { AuthenticationInterceptor } from './http-interceptors/authentication-interceptor';
import { ErrorInterceptor } from './http-interceptors/error-interceptor';
import { AuthenticationService } from './services/authentication.service';
import { RxStompService } from './services/rx-stomp.service';

export function initAuth(authenticationService: AuthenticationService) {
  return () => authenticationService.initAuth();
}

@NgModule({
  declarations: [SessionExpiredModalComponent, LoginComponent],
  imports: [
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        // allowedUrls: [
        //   `${environment.API.orchestrator_analysis}`,
        //   `${environment.API.orchestrator_analysis_v2}`,
        //   `${environment.API.orchestrator_configurator}`,
        //   `${environment.API.orchestrator_chat}`,
        //   `${environment.API.channel_whatsapp}`,
        //   `${environment.API.channel_vivocha}`,
        //   `${environment.API.channel_liveperson}`,
        //   `${environment.API.channel_timrcc}`,
        //   `${environment.API.channel_facebook}`,
        //   `${environment.API.channel_google}`,
        //   `${environment.API.channel_teams}`,
        // ],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initAuth, deps: [AuthenticationService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthenticationService,
    RxStompService,
  ],
  exports: [LoginComponent],
})
export class AuthenticationModule {}
