import { Component, AfterViewInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import Chart from 'chart.js/auto/auto.esm';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements AfterViewInit, OnDestroy {
  @Input() legend = false;
  @Input() labels: string[];

  _dataset: { data: number[]; label: string; colors: string[] }[];
  get dataset(): { data: number[]; label: string; colors: string[] }[] {
    return this._dataset;
  }
  @Input() set dataset(dataset: { data: number[]; label: string; colors: string[] }[]) {
    this._dataset = dataset;
    if (this.chartRef) {
      this.updateChart();
    }
  }

  @ViewChild('barChartWrapper') barChart: ElementRef;
  chartRef;

  ngAfterViewInit() {
    const config = {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: this.dataset.map(({ data, label, colors }) => ({ data, label, borderColor: colors, backgroundColor: colors })),
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
          },
        },
        plugins: {
          legend: {
            display: this.legend,
            position: 'top',
            align: 'start',
            labels: {
              padding: 20,
              color: '#000000',
              font: {
                size: 11,
                family: 'Poppins, sans-serif',
              },
              boxWidth: 8,
              usePointStyle: true,
              pointStyle: 'circle',
              textAlign: 'left',
            },
          },

          tooltip: {
            displayColors: true,
            backgroundColor: 'rgba(255, 255, 255)',
            bodyAlign: 'left',
            bodyColor: 'rgba(0, 0, 0)',
            titleColor: 'rgba(0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0.8)',
            borderWidth: 0.5,
            usePointStyle: true,
            pointStyle: 'circle',
          },
        },
      },
    };
    this.chartRef = new Chart(this.barChart.nativeElement, config);
  }

  ngOnDestroy() {
    if (this.chartRef instanceof Chart) this.chartRef.destroy();
  }
  updateChart() {
    this.chartRef.options.plugins.legend.display = this.legend;
    this.chartRef.data.labels = this.labels;
    this.chartRef.data.datasets = this.dataset.map(({ data, label, colors }) => ({ data, label, borderColor: colors, backgroundColor: colors }));
    this.chartRef.update();
  }
}
