<div class="box_wrapper">
    <div class="dashboard_chunk">
        <h6 class="chunk_title">Monitoring</h6>
        <div class="chunk_body flex-grow-1" [spinnerLoader]="componentLoaded">
            <div class="row mt-2">
                <div class="col-12">
                    <div *ngFor="let pod of pods" class="mb-2">
                        <i aria-hidden="true" class="fas fa-xs fa-circle pe-1" [ngClass]="pod.status === 'UP' ? 'text-success' : 'text-danger'"></i>
                        {{ pod.name }}
                    </div>
                </div>
            </div>
        </div>
        <footer class="chunk_bottom">
            <button type="button" class="btn brand_light" [routerLink]="['/monitoring']">View details</button>
        </footer>
    </div>
</div>
