<div class="box_wrapper">
    <div class="dashboard_chunk">
        <h6 class="chunk_title">Components Status</h6>
        <div class="chunk_body">
            <table class="table table-sm stats_table" aria-hidden="true">
                <tbody>
                    <tr>
                        <td><i class="fas fa-circle fa-xs text-success" aria-hidden="true"></i></td>
                        <td>Dialogflow</td>
                    </tr>
                    <tr>
                        <td><i class="fas fa-circle fa-xs text-success" aria-hidden="true"></i></td>
                        <td>Natural language API</td>
                    </tr>
                    <tr>
                        <td><i class="fas fa-circle fa-xs text-warning" aria-hidden="true"></i></td>
                        <td>Cloud SQL</td>
                    </tr>
                    <tr>
                        <td><i class="fas fa-circle fa-xs text-success" aria-hidden="true"></i></td>
                        <td>Big Query</td>
                    </tr>
                    <tr>
                        <td><i class="fas fa-circle fa-xs text-danger" aria-hidden="true"></i></td>
                        <td>Data Loss Prevention</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="chunk_bottom">
            <button type="button" class="btn brand_light">View details</button>
        </footer>
    </div>
</div>