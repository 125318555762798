import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'tone-of-voice-card',
  templateUrl: './tone-of-voice-card.component.html',
  styleUrls: ['./tone-of-voice-card.component.scss'],
  standalone: true,
  imports: [RouterModule],
})
export class ToneOfVoiceCardComponent {}
