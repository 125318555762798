import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss'],
})
export class PromptModalComponent implements OnInit {
  prompts: string[];
  parameterName: string;
  promptsForm: FormGroup = this.fb.group({
    prompts: this.fb.array([]),
  });
  get promptsFormArray(): FormArray {
    return this.promptsForm.get('prompts') as FormArray;
  }
  @Output() saved: EventEmitter<any> = new EventEmitter();
  promptSubmitted = false;
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.prompts?.length > 0) {
      this.prompts.forEach((prompt) => this.promptsFormArray.push(this.fb.control(prompt, Validators.required)));
    } else {
      this.promptsFormArray.push(this.fb.control('', Validators.required));
    }
  }

  addPrompt() {
    this.promptsFormArray.push(this.fb.control('', Validators.required));
  }

  savePrompt() {
    this.promptSubmitted = true;
    if (this.promptsFormArray.invalid) return;
    this.saved.emit(this.promptsFormArray.value);
    this.activeModal.close();
  }
}
