import { NgModule } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { RxStompService } from '../services/rx-stomp.service';
import { TEST_AUTHENTICATION_SERVICE, TEST_RXSTOMP_SERVICE } from './services.mock';

@NgModule({
  providers: [
    { provide: RxStompService, useValue: TEST_RXSTOMP_SERVICE },
    { provide: AuthenticationService, useValue: TEST_AUTHENTICATION_SERVICE },
  ],
})
export class AuthenticationModuleMocked {}
