import { Component, Input } from '@angular/core';

@Component({
  selector: 'console-menu',
  templateUrl: './console-menu.component.html',
  styleUrls: ['./console-menu.component.scss'],
})
export class ConsoleMenuComponent {
  @Input() stretchMenu: boolean;
  subDesign = false;
  subHealthCheck = false;

  isChildActive(element: any, subMenu) {
    for (const listElement of element.querySelectorAll('ul.sub_items li')) {
      if (listElement.classList.contains('active')) {
        this[subMenu] = true;
        return true;
      }
    }
  }
}
