import { inject } from '@angular/core';
import { Environment } from '@environment-token';
import { RxStomp, RxStompConfig } from '@stomp/rx-stomp';
import { OAuthService } from 'angular-oauth2-oidc';

export const wsRxStompConfig = (oAuthService: OAuthService, env: Environment): RxStompConfig => {
  return {
    // Which server?
    brokerURL: env.websocket_path,

    // Headers
    // Typical keys: login, passcode, host
    // connectHeaders: {
    //     login: 'guest',
    //     passcode: 'guest',
    // },

    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 5000, // Typical value 0 - disabled
    heartbeatOutgoing: 5000, // Typical value 20000 - every 20 seconds

    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 500 (500 milli seconds)
    reconnectDelay: 1000,

    // Will log diagnostics on console
    // It can be quite verbose, not recommended in production
    // Skip this key to stop logging to console
    // debug: (msg: string): void => {
    //     console.log(new Date(), msg);
    // },
    // webSocketFactory: () => {
    //   // return new SockJS(`${env.API.orchestrator_configurator}/stomp?access_token=${accessToken}`);
    //   return new SockJS(`${env.API.orchestrator_configurator}/stomp`);
    // },

    beforeConnect(stompClient: RxStomp) {
      stompClient.stompClient.brokerURL = `${env.websocket_path}?access_token=${oAuthService.getAccessToken()}`;
    },
  };
};
