import { HttpClientTestingModule } from '@angular/common/http/testing';
import { NgModule } from '@angular/core';
import { RouterTestingModule } from '@angular/router/testing';
import { NotificationService } from '../services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TEST_NOTIFICATION_SERVICE } from './services.mock';

@NgModule({
  imports: [HttpClientTestingModule, RouterTestingModule],
  providers: [
    NgbActiveModal,
    { provide: NotificationService, useValue: TEST_NOTIFICATION_SERVICE }
  ],
  exports: [HttpClientTestingModule, RouterTestingModule],
})
export class CommonUiModuleMocked {}
