import { Pipe, PipeTransform } from '@angular/core';
import { OrchestrateMessage } from '../models/orchestrate-message.model';

@Pipe({
  name: 'graphicPagination',
})
export class GraphicPaginationPipe implements PipeTransform {
  transform(message: OrchestrateMessage): any[] {
    if (!message.paginated) return message.items;

    const firstItem = message.firstItem || 0;
    return message.items.slice(firstItem, firstItem + message.pageSize);
  }
}
