export interface Account {
  id: number;
  code: string;
  name: string;
  customerId: number;
  customerName: string;
  startDate: number;
  endDate: number;
  projectId: string;
  bucketName?: any;
  email?: string;
  serviceAccount: string;
}
