import { DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'analyticalFormatter',
})
export class AnalyticalFormatterPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe, private datePipe: DatePipe) {}

  transform(value: any, args: any = {}): any {
    if (!value && value !== 0) {
      return args.blankValue || 'NA';
    } else {
      switch (args.type) {
        case 'date':
          return this.datePipe.transform(value, args.format);
        case 'number':
          return this.decimalPipe.transform(value, args.format);
        default:
          return value;
      }
    }
  }
}
