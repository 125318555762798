<div class="row section_wrapper">
    <div class="col-12">
        <ul ngbNav #analyticsNav="ngbNav" class="nav-tabs analytic_tabs">
            <li [ngbNavItem]="'health_tatus'">
                <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/monitoring/status']">Status</a>
            </li>
            <li [ngbNavItem]="'logs'" *roleRestriction="'monitoring.logs.view'">
                <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/monitoring/logs']">Logs</a>
            </li>
        </ul>
    </div>
    <div class="col-12">
        <router-outlet></router-outlet>
    </div>
</div>