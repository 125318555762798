import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationStart, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserAccountService } from '../services/user-account.service';
import { NotificationService } from '@common-ui';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleRestrictionGuard implements CanActivate {
  accountSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(private router: Router, private userAccountService: UserAccountService, private notificationService: NotificationService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.routeSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      if (this.accountSubscription instanceof Subscription) {
        this.accountSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
      }
    });

    return new Promise<boolean>((resolve, reject) => {
      this.accountSubscription = this.userAccountService.sessionAccount$.subscribe(() => {
        if (this.userAccountService.isAuthorized(next.data['roleRestriction'])) {
          resolve(true);
        } else {
          this.router.navigate(['']);
          this.notificationService.showToast('Your Account is not configured for access this section', { type: 'warning' });
          reject(false);
        }
      });
    });
  }
}
