import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { CommonService } from '@shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ToneOfVoiceService {
  constructor(private http: HttpClient, private commonService: CommonService, @Inject(ENVIRONMENT) private env: Environment) {}

  getToneOfVoiceQuestions(lang: string): Observable<any> {
    return this.http.get(`./assets/tone-of-voice/i18n/questions_${lang}.json`);
  }

  getToneOfVoiceResults(data: any): Observable<any> {
    return this.http.post(`${this.env.API.orchestrator_configurator}/personality/result`, data);
  }

  importToneOfVoiceResults(file: File): Observable<any> {
    const data = new FormData();
    data.append('file', file, file.name);

    return this.http.post(`${this.env.API.orchestrator_configurator}/personality/result/upload`, data);
  }

  exportToneOfVoiceResults(data: any): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as const,
    };

    return this.http.post(`${this.env.API.orchestrator_configurator}/personality/result/download`, data, httpOptions).pipe(
      tap((response) => {
        this.commonService.downloadFile(response, 'blob', 'Tone of Voice Results.xlsx');
      })
    );
  }
}
