import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DateParserFormatter } from '../date-parser-formatter';
import { DatepickerAdapter } from '../datepicker-adapter';
import { Period } from '../period.model';

@Component({
  selector: 'datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerRangeComponent),
      multi: true,
    },
    { provide: NgbDateAdapter, useClass: DatepickerAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
  ],
})
export class DatepickerRangeComponent implements ControlValueAccessor {
  fromDate: NgbDate;
  toDate: NgbDate;

  onChange: Function;
  onTouch: Function;
  private _period: Period;

  set period(period: Period) {
    this._period = period;
    this.onChange?.(period);
    this.onTouch?.(period);
  }
  // This will will write the value to the view if the the value changes occur on the model programmatically
  writeValue(period: Period) {
    if (period) {
      this._period = period;

      if (period.startDate) {
        const startDate = new Date(period.startDate);
        this.fromDate = new NgbDate(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
      }
      if (period.endDate) {
        const endDate = new Date(period.endDate);
        this.toDate = new NgbDate(endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate());
      }
    }
  }

  // When the value in the UI is changed, this method will invoke a callback function
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // When the element is touched, this method will get called
  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  hoveredDate: NgbDate;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {}

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.period = {
      startDate: this.fromDate ? new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0).getTime() : null,
      endDate: this.toDate ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23, 59, 59).getTime() : null,
    };
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(input: string, rangeType: 'startDate' | 'endDate') {
    const parsed = this.formatter.parse(input);
    if (parsed && this.calendar.isValid(NgbDate.from(parsed))) {
      if (rangeType === 'startDate') {
        this.fromDate = NgbDate.from(parsed);
        this.period = {
          startDate: new Date(parsed.year, parsed.month - 1, parsed.day, 0, 0, 0).getTime(),
          endDate: this._period.endDate,
        };
      } else if (rangeType === 'endDate') {
        this.toDate = NgbDate.from(parsed);

        this.period = {
          startDate: this._period.startDate,
          endDate: new Date(parsed.year, parsed.month - 1, parsed.day, 23, 59, 59).getTime(),
        };
      }
    }
  }
}
