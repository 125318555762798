<div class="box_wrapper">
    <div class="dashboard_chunk">
        <h6 class="chunk_title">Tone of voice</h6>
        <div class="chunk_body">
            <div class="row">
                <div class="col">
                    <p class="d-block">Take a test to define the personality and tone of voice of your company's bot</p>
                    <img src="./assets/tone-of-voice/robot_chat.svg" alt="" />
                </div>
            </div>
        </div>
        <footer class="chunk_bottom">
            <button type="button" class="btn brand_light" [routerLink]="['/tone-of-voice']">More info</button>
        </footer>
    </div>
</div>
