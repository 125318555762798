import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '../../classes/pagination';


@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Output() paginationCallback = new EventEmitter<number>();
  @Input() paginationData: Pagination;
  @Input() resultPerPage = true;

  changePage(page: number) {
    this.paginationCallback.emit(page);
  }
}
