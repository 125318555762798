

<ng-template ngFor let-toast [ngForOf]="notificationService.toasts">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ngb-toast [autohide]="toast.autohide || true" [delay]="toast.delay || 5000" [ngClass]="{'bg-success': toast.type === 'success', 'bg-danger': toast.type === 'danger', 'bg-warning': toast.type === 'warning', 'text-light': toast.type === 'success' || toast.type === 'danger'}" (hide)="notificationService.removeToast(toast)">

            <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>

        </ngb-toast>
    </ng-template>

    <ng-template #text>
        <ngb-toast [autohide]="toast.autohide || true" [delay]="toast.delay || 5000" [ngClass]="{'bg-success': toast.type === 'success', 'bg-danger': toast.type === 'danger', 'bg-warning': toast.type === 'warning', 'text-light': toast.type === 'success' || toast.type === 'danger'}" (hide)="notificationService.removeToast(toast)">

            <ng-template ngbToastHeader>
                <span *ngIf="toast.type === 'success'"><i class="fas fa-check-circle" aria-hidden="true"></i> Success</span>
                <span *ngIf="toast.type === 'danger'"><i class="fas fa-exclamation-circle" aria-hidden="true"></i> Error</span>
                <span *ngIf="toast.type === 'warning'"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i> Warning</span>
            </ng-template>
            <span [innerHTML]="toast.textOrTpl"></span>

        </ngb-toast>
    </ng-template>
</ng-template>
