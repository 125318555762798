<div class="console_modal export_logs_dialog">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="exportData === 'logs'; else templateExportAuditLogs">Export Log</h4>
        <ng-template #templateExportAuditLogs>
            <h4 class="modal-title">Export Audit Log</h4>
        </ng-template>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>

    <div class="modal-body" [spinnerLoader]="exportSubscription?.closed">
        <div class="body_content">
            <form #exportForm="ngForm" class="export_logs_form" (ngSubmit)="export()">
                <div class="mb-3">
                    <label for="bucketName" class="form-label">Bucket name</label>
                    <input name="bucketName" type="text" id="bucketName" class="form-control" placeholder="Insert Bucket name..." required [(ngModel)]="storageInfo.bucketName" #bucketName="ngModel" [ngClass]="{'is-invalid': bucketName.invalid && exportForm.submitted}">
                    <div class="invalid-feedback" *ngIf="bucketName.errors?.required">Bucket Name is required</div>
                </div>
                <div class="mb-3">
                    <label for="projectId" class="form-label">Project ID</label>
                    <input name="projectId" type="text" id="projectId" class="form-control" placeholder="Insert your Project ID..." required [(ngModel)]="storageInfo.projectId" #projectId="ngModel" [ngClass]="{'is-invalid': projectId.invalid && exportForm.submitted}">
                    <div class="invalid-feedback" *ngIf="projectId.errors?.required">Project ID is required</div>
                </div>
                <div>
                    <label class="form-label">Service Account</label>
                    <div class="input-group">
                        <label for="importServiceAccount">
                            <button class="btn btn-sm brand_light"><i class="fal fa-cloud-upload" aria-hidden="true"></i> Browse file</button>
                            <span class="ms-1" *ngIf="uploadedFileName">{{uploadedFileName}}</span>
                        </label>
                        <input type="file" id="importServiceAccount" class="form-control" required (change)="uploadServiceAccount($event.target)">
                    </div>
                    <small class="text-danger" *ngIf="!uploadedFileName && exportForm.submitted">Service Account File is required</small>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn brand_light" (click)="activeModal.close()">Cancel</button>
        <button type="button" class="btn brand_default" (click)="exportForm.onSubmit($event)">Export</button>
    </div>
</div>
