import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullTextSearch',
})
export class FullTextSearchPipe implements PipeTransform {
  transform(items: Array<any>, value: string, target?: string): Array<any> {
    if (!value) {
      return items;
    }

    if (!target) {
      return items.filter((item) => item.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    const parts = target.split('.');

    return items.filter((item) => {
      const targetValue = parts.reduce((object, key) => object?.[key], item);
      return targetValue?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });
  }
}
