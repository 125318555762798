
<div class="row align-items-center">
    <div [ngClass]="resultPerPage ? 'col-4 offset-4': 'col-12 d-flex justify-content-center'">
        <ngb-pagination [collectionSize]="paginationData.totalItems" [maxSize]="1" [pageSize]="paginationData.pageSize" [(page)]="paginationData.currentPage" (pageChange)="changePage(paginationData.currentPage)" aria-label="Results pagination">
            <ng-template ngbPaginationPrevious><i class="fas fa-chevron-left" aria-hidden="true"></i></ng-template>
            <ng-template ngbPaginationNumber let-p>
                <form #paginationForm="ngForm" class="paginationForm" novalidate (ngSubmit)="changePage(paginationData.pageTarget)" *ngIf="p == paginationData.currentPage">
                    <input type="number" name="movePage" class="form-control" min="1" [(ngModel)]="paginationData.pageTarget" [value]="p" #movePage="ngModel"> / {{paginationData.totalPages}}
                </form>
            </ng-template>
            <ng-template ngbPaginationEllipsis></ng-template>
            <ng-template ngbPaginationNext><i class="fas fa-chevron-right" aria-hidden="true"></i></ng-template>
        </ngb-pagination>
    </div>
    <div class="col-4" *ngIf="resultPerPage && paginationData.totalItems > 25">
        <form #paginationForm="ngForm" class="paginationSize" novalidate>
            <div class="row justify-content-end size_select">
                <label for="setSize" class="col-auto">Results per Page:</label>
                <div class="col-auto">
                    <select name="setSize" id="setSize" class="form-select" [(ngModel)]="paginationData.pageSize" #setSize="ngModel" (change)="changePage(1)">
                        <option [value]="10">10</option>
                        <option [value]="15">15</option>
                        <option [value]="25">25</option>
                        <option [value]="50" *ngIf="paginationData.totalItems > 50">50</option>
                        <option [value]="75" *ngIf="paginationData.totalItems > 75">75</option>
                    </select>
                </div>
            </div>
        </form>
    </div>
</div>
