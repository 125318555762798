import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserAccountService } from '../services/user-account.service';

@Injectable({
  providedIn: 'root',
})
export class SessionGuard implements CanActivate {
  constructor(private userAccountService: UserAccountService) {}

  canActivate(): Observable<boolean> {
    return this.userAccountService.sessionLoaded ? of(true) : this.userAccountService.initSession();
  }
}
