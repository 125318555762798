import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModal, NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Pagination, promptsRequiredValidator, SharedModule, uniqueValidator, PromptModalComponent, CommonApiService } from '@shared';
import { Subject } from 'rxjs';
import { PhrasePartsDirective } from '../../directives/phrase-parts.directive';

@Component({
  selector: 'training-phrases-field',
  templateUrl: './training-phrases-field.component.html',
  styleUrls: ['./training-phrases-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, FormsModule, NgbModule, SharedModule, ReactiveFormsModule, RouterModule, PhrasePartsDirective],
})
export class TrainingPhrasesFieldComponent implements OnDestroy, OnChanges {
  @ViewChildren('popEntity') popEntity: QueryList<NgbPopover>;
  @ViewChildren('popParameterEntity') popParameterEntity: QueryList<NgbPopover>;
  @ViewChild('inputForm') inputForm: NgForm;
  @Input() intent: any;
  @Input() intentForm: FormGroup;
  @Input() parametersManagement?: boolean = true;
  @Input() entitiesManagement?: boolean = true;
  @Input() intentSubmitted?: boolean = false;
  pageSize = 8;
  pagination: Pagination = new Pagination(this.pageSize);

  inputPhrase: string;

  subscriptions: object = {};

  entityPopOffset: string;
  private popPhraseIndex: number;
  private popParameterIndex: number;

  onUpdateEntities: Array<Subject<any>> = [];
  entities: Array<any> = [];
  patchedEntities: Array<any> = [];
  textFilter = '';
  get entityParameters() {
    return this.intentForm.get('parameters') as FormArray;
  }
  get trainingPhrases() {
    return this.intentForm.get('phrases') as FormArray;
  }

  getTrainingPhrasesForm({ alias = '', entityType = '', text = '' }) {
    return this.fb.group({
      alias: alias,
      entityType: entityType,
      text: text,
    });
  }

  getParameterPrompts(index) {
    return this.intentForm.get(['parameters', index, 'prompts']) as FormArray;
  }

  constructor(private modalService: NgbModal, private fb: FormBuilder, private commonApiService: CommonApiService) {}

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.intent && changes.intent.currentValue) {
      if (this.entitiesManagement) this.getEntities(this.intent);
      this.fillArrayFields(this.intent);
    }
  }

  getEntities(intent: any) {
    const params = {
      agentLang: intent.language,
    };
    this.subscriptions['Entities'] = this.commonApiService.getEntityNames(params).subscribe((response: any) => {
      this.entities = response;
    });
  }

  patchEntityParameters() {
    const entities = this.entities.reduce((accumulator, currentValue) => {
      (accumulator || []).push({
        entityType: currentValue,
      });
      return accumulator;
    }, []);

    this.patchedEntities = [...entities, ...this.entityParameters.value].sort((a, b) => a.entityType.localeCompare(b.entityType));
  }

  fillArrayFields(intent: any) {
    this.entityParameters.clear();
    this.trainingPhrases.clear();

    intent.parameters.forEach((parameter: any) => {
      this.entityParameters.push(
        this.fb.group(
          {
            id: [parameter.id],
            name: [parameter.name, [Validators.required, uniqueValidator]],
            entityType: [parameter.entityType],
            required: [parameter.required],
            value: [parameter.value, Validators.required],
            prompts: this.processPrompts(parameter.prompts),
          },
          { validators: promptsRequiredValidator }
        )
      );
    });
    intent.phrases.forEach((phrase) => {
      this.trainingPhrases.push(
        this.fb.group({
          id: phrase.id,
          parts: this.processParts(phrase.parts),
        })
      );
      this.onUpdateEntities.push(new Subject<any>());
    });
    this.pagination.updateTotals(this.trainingPhrases.length);
  }

  private processPrompts(prompts: Array<any>): FormArray {
    const promptsArray = this.fb.array([]);
    (prompts || []).forEach((prompt) => {
      promptsArray.push(this.fb.control(prompt, Validators.required));
    });
    return promptsArray;
  }
  private processParts(parts: Array<any>): FormArray {
    const partsArray = this.fb.array<FormGroup>([]);
    parts.forEach((part) => {
      partsArray.push(this.getTrainingPhrasesForm(part));
    });
    return partsArray;
  }

  addTrainingPhrase() {
    if (this.inputForm.invalid) return;

    this.trainingPhrases.insert(
      0,
      this.fb.group({
        parts: this.fb.array([this.getTrainingPhrasesForm({ alias: '', entityType: '', text: this.inputPhrase })]),
      })
    );
    this.onUpdateEntities.push(new Subject<any>());
    this.inputForm.resetForm();
    this.selectPage(1);
  }

  selectPage(pageNumber) {
    this.pagination.onSelectPage(pageNumber);
    this.pagination.updateTotals(this.trainingPhrases.length);
  }
  removeTrainingPhrases(indexPrhase: number) {
    this.trainingPhrases.removeAt(indexPrhase);
  }

  togglePopEntity($event: any, phraseIndex: number) {
    if (!this.entitiesManagement) return;
    const popIndex = phraseIndex - this.pagination.getFirstItemIndex();
    this.popPhraseIndex = phraseIndex;
    this.entityPopOffset = `${$event.offset}px`;

    this.patchEntityParameters();

    if (this.popEntity.toArray()[popIndex].isOpen()) {
      this.popEntity.toArray()[popIndex].close();
    } else {
      this.textFilter = '';
      this.popEntity.toArray()[popIndex].open({ $event });
    }
  }

  setEntity(entity: any | null, popSelection: any) {
    const entityParameter = this.joinParameters(entity);
    this.onUpdateEntities[this.popPhraseIndex].next({
      entity: entityParameter,
      text: popSelection.text,
      alias: popSelection.alias || null,
      entityType: popSelection.entityType || null,
    });

    this.popEntity.toArray()[this.popPhraseIndex - this.pagination.getFirstItemIndex()].close();
  }

  joinParameters(entity: any): object {
    if (!entity) return {};

    const obtainParameter = this.entityParameters.value.find((parameter) => parameter.name === entity.name);

    if (!obtainParameter) {
      return this.addParameter(entity.entityType);
    } else {
      return obtainParameter;
    }
  }

  addParameter(entityType = '') {
    const alias: string = this.parseAliasRestriction(entityType);

    const newFormGroup = this.fb.group(
      {
        name: [alias, [Validators.required, uniqueValidator]],
        entityType: [entityType, Validators.required],
        required: [false],
        value: [entityType ? `$${entityType.replace('@', '')}` : '', Validators.required],
        prompts: this.processPrompts([]),
      },
      { validators: promptsRequiredValidator }
    );
    this.entityParameters.push(newFormGroup);

    return newFormGroup.value;
  }

  parseAliasRestriction(entityType: string): string {
    const replaceRestriction = (text: string): string => {
      return text.replace('@', '').replace('.', '-');
    };

    if (this.entityParameters.value.some((parameter) => parameter.name === replaceRestriction(entityType))) {
      return replaceRestriction(entityType) + new Date().getTime();
    } else {
      return replaceRestriction(entityType);
    }
  }

  togglePopParameterEntity(parameterIndex: number, parameter: AbstractControl) {
    if (!this.parametersManagement) return;

    this.popParameterIndex = parameterIndex;

    if (this.popParameterEntity.toArray()[parameterIndex].isOpen()) {
      this.popParameterEntity.toArray()[parameterIndex].close();
    } else {
      this.textFilter = '';
      this.popParameterEntity.toArray()[parameterIndex].open({ parameter });
    }
  }

  updateEntityParameter(entityType: string, parameter: AbstractControl) {
    const alias = this.parseAliasRestriction(entityType);

    if (!parameter.get('name').value) {
      parameter.patchValue({
        name: alias,
        entityType: entityType,
        value: `$${entityType.replace('@', '')}`,
      });
    } else {
      parameter.get('entityType').setValue(entityType);
    }

    this.popParameterEntity.toArray()[this.popParameterIndex].close();
  }

  editPrompt(i) {
    const componentRef: PromptModalComponent = this.modalService.open(PromptModalComponent, {
      backdropClass: 'w-100 h-100 position-absolute',
      windowClass: 'position-absolute',
      container: 'div.intent_sidebar',
      centered: true,
    }).componentInstance;
    componentRef.prompts = this.getParameterPrompts(i).value;
    componentRef.parameterName = this.intentForm.get(['parameters', i, 'name']).value;

    componentRef.saved.subscribe((prompts) => {
      (this.intentForm.get(['parameters', i]) as FormGroup).setControl('prompts', this.processPrompts(prompts));
    });
  }
}
