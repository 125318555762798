<div class="console_modal intent_dialog design_layout">
    <div class="modal-header">
        <h4 class="modal-title">Prompt for "{{ parameterName }}"</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>

    <div class="modal-body">
        <div class="body_content">
            <table class="table entity_parameters design_layout" aria-describedby="Prompt Table" [formGroup]="promptsForm">
                <thead>
                    <tr>
                        <th scope="col">Prompt</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody formArrayName="prompts">
                    <tr *ngFor="let prompt of promptsFormArray.controls; index as i">
                        <td>
                            <input type="text" name="prompt" class="form-control" placeholder="Enter prompt action..." [formControlName]="i" required [ngClass]="{ 'is-invalid': prompt.errors && promptSubmitted }" />
                            <div class="invalid-feedback" *ngIf="prompt.errors?.required">Prompt is required</div>
                        </td>
                        <td class="action_column_sm">
                            <button class="btn text-muted" (click)="promptsFormArray.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <button class="btn p-0 text_design" (click)="addPrompt()">
                                <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn design_default" (click)="savePrompt()">Save</button>
    </div>
</div>
