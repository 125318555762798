
interface API {
    orchestrator_analysis: string;
    orchestrator_analysis_v2: string;
    orchestrator_configurator: string;
    orchestrator_rasa_model: string;
    orchestrator_chat: string;
    channel_whatsapp: string;
    channel_vivocha: string;
    channel_liveperson: string;
    channel_timrcc: string;
    channel_facebook: string;
    channel_google: string;
    channel_teams: string;
    channel_telegram: string;
}

interface AUTHENTICATION {
    keycloak_issuer: string;
    keycloak_client: string;
}

export interface Environment {
    production: boolean;
    BASE_PATH: string;
    API: API;
    websocket_path: string;
    AUTHENTICATION: AUTHENTICATION;
}