import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty',
  template: `<div></div>`,
})
export class EmptyComponent {}

@Component({
  selector: 'doughnut-chart',
  template: `<div></div>`,
})
export class DoughnutChartMockedComponent {
  @Input() legend: boolean;
  @Input() chartTitle: string;

  _dataset: { data: number[]; labels: string[] | string[][]; colors: string[] };
  get dataset(): { data: number[]; labels: string[] | string[][]; colors: string[] } {
    return this._dataset;
  }
  @Input() set dataset(dataset: { data: number[]; labels: string[] | string[][]; colors: string[] }) {
    this._dataset = dataset;
  }
}

@Component({
  selector: 'circle-progress-bar',
  template: `<div></div>`,
})
export class CircleProgressBarMockedComponent {
  _remaining: number;
  get remaining(): number {
    return this._remaining;
  }
  @Input() set remaining(remaining: number) {
    this._remaining = remaining;
  }

  @Input() total: number;
  @Input() mainTitle: string;
  @Input() unit: string;
  @Input() tooltips: { title: string; labels: string[] }[];
}

@Component({
  selector: 'line-chart',
  template: `<div></div>`,
})
export class LineChartMockedComponent {
  private _legend = false;
  public get legend() {
    return this._legend;
  }
  @Input() public set legend(value) {
    this._legend = value;
  }

  private _labels: string[];
  public get labels(): string[] {
    return this._labels;
  }
  @Input() public set labels(value: string[]) {
    this._labels = value;
  }

  _dataset: { data: number[]; label: string; color: string }[];
  get dataset(): { data: number[]; label: string; color: string }[] {
    return this._dataset;
  }
  @Input() set dataset(dataset: { data: number[]; label: string; color: string }[]) {
    this._dataset = dataset;
  }
}

@Component({
  selector: 'bar-chart',
  template: `<div></div>`,
})
export class BarChartMockedComponent {
  @Input() legend = false;
  @Input() labels: string[];

  _dataset: { data: number[]; label: string; colors: string[] }[];
  get dataset(): { data: number[]; label: string; colors: string[] }[] {
    return this._dataset;
  }
  @Input() set dataset(dataset: { data: number[]; label: string; colors: string[] }[]) {
    this._dataset = dataset;
  }
}
