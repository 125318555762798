import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenResponse } from 'angular-oauth2-oidc';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private refreshingToken = false;
  private refreshTokenObservable: Observable<TokenResponse>;

  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleTokenExpired(req, next);
        } else if (error.status === 403) {
          this.authenticationService.logout();
        }
        return throwError(error);
      })
    );
  }

  private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshingToken) {
      this.refreshingToken = true;
      this.refreshTokenObservable = from(this.authenticationService.refreshToken());

      return this.refreshTokenObservable.pipe(
        switchMap(({ access_token }) => {
          this.refreshingToken = false;
          return next.handle(this.updateRequestBearer(request, access_token));
        }),
        catchError((error: HttpErrorResponse) => {
          this.refreshingToken = false;
          this.authenticationService.logout();
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenObservable.pipe(switchMap(({ access_token }) => next.handle(this.updateRequestBearer(request, access_token))));
    }
  }

  private updateRequestBearer(request, token) {
    const headers = request.headers.set('Authorization', `Bearer ${token}`);
    return request.clone({ headers });
  }
}
