import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProperties',
})
export class FilterPropertiesPipe implements PipeTransform {
  transform(items: any[], filter: object): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => Object.keys(filter).every((key) => item[key] === filter[key]));
  }
}
