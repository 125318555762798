export class Pagination {
  pageTarget = 1;
  currentPage = 1;
  pageSize: number;
  totalPages: number;
  totalItems: number;

  constructor(pageSize?: number) {
    this.pageSize = pageSize || 25;
  }

  onSelectPage(pageNumber: number) {
    this.pageTarget = pageNumber;
    this.currentPage = pageNumber;
  }

  getPageIndex(): number {
    return this.currentPage - 1;
  }

  getFirstItemIndex(): number {
    return this.pageSize * (this.currentPage - 1);
  }

  getLastItemIndex(): number {
    return this.pageSize * this.currentPage;
  }
  updateTotals(totalItems: number) {
    this.totalItems = totalItems;
    this.totalPages = Math.ceil(totalItems / this.pageSize);
  }
}
