import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class DatepickerAdapter extends NgbDateAdapter<string> {
  fromModel(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    const date = new Date(parseInt(value));
    if (value) {
      result = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    }
    return result;
  }

  toModel(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      result = new Date(`${date.year}/${date.month}/${date.day}`).getTime().toString();
    }
    return result;
  }
}
