<div class="datepicker_range">
    <div class="dp-hidden">
        <input name="conversationsRange" placement="bottom-end" class="form-control" ngbDatepicker #conversationsRange="ngbDatepicker" outsideDays="hidden" [displayMonths]="2" [autoClose]="'outside'" [dayTemplate]="t" (dateSelect)="onDateSelection($event)" />
        <ng-template #t let-date let-focused="focused">
            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">{{ date.day }}</span>
        </ng-template>
    </div>
    <div class="input-group">
        <input name="rangeFrom" placeholder="Date from..." class="form-control" #rangeFrom [value]="formatter.format(fromDate)" (input)="validateInput(rangeFrom.value, 'startDate')" />
        <input name="rangeTo" placeholder="Date to..." class="form-control" #rangeTo [value]="formatter.format(toDate)" (input)="validateInput(rangeTo.value, 'endDate')" />
        <button type="button" class="btn btn-outline-secondary" (click)="conversationsRange.toggle()"><i class="fas fa-calendar-alt" aria-hidden="true"></i></button>
    </div>
</div>
