import { Component, TemplateRef } from '@angular/core';
import { NotificationService } from '@common-ui';



@Component({
  selector: 'toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  host: {'class': 'toast-container'}
})
export class ToastNotificationComponent {

  constructor(public notificationService: NotificationService) { }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

}
