<div class="p-3 component_status_card">
    <div class="row">
        <div class="col-8">
            <i aria-hidden="true" class="fas fa-xs fa-circle pe-1" [ngClass]="status === 'UP' ? 'text-success' : 'text-danger'"></i>
            {{ name }}
        </div>
        <div class="col-2">
            <span class="fas fa-lg fa-sync-alt" [ngbTooltip]="'Refresh Status'" (click)="refreshAgentStatus()"></span>
            <ng-template [spinnerLoader]="isRefreshed" [size]="'sm'"></ng-template>
        </div>
        <div class="col-2">
            <span class="fas fa-lg fa-redo-alt" [ngbTooltip]="'Restart Agent'"(click)="restartAgent()"></span>
            <ng-template [spinnerLoader]="isRestarted" [size]="'sm'"></ng-template>
        </div>
    </div>
</div>
