import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgentType } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService } from '@shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss'],
})
export class RatingModalComponent implements OnInit, OnDestroy {
  public sessionId: string;
  public agentBotType: AgentType;

  interactionsSubscription: Subscription;

  interactions: Array<any> = [];

  get fallbackCount(): number {
    return this.interactions.filter((i) => i.fallback).length;
  }

  constructor(public activeModal: NgbActiveModal, private commonApiService: CommonApiService) {}

  ngOnInit() {
    this.getInteractions(this.sessionId);
  }

  ngOnDestroy() {
    this.interactionsSubscription.unsubscribe();
  }

  getInteractions(sessionId: string) {
    this.interactionsSubscription = this.commonApiService.getInteractions(sessionId).subscribe((response: any) => {
      this.interactions = response.data;
    });
  }
}
