export interface Role {
  id: number;
  name: string;
  description?: string;
  defaultRole: boolean;
  accountId?: number;
  permissions: string[];
  userAccounts: any[];
  usedBy: number;
}
