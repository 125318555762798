export enum Languages {
  af = 'Afrikaans',
  am = 'Amharic',
  ar = 'Arabic',
  az = 'Azerbaijani',
  be = 'Belarusian',
  bg = 'Bulgarian',
  bn = 'Bengali',
  bs = 'Bosnian',
  ca = 'Catalan',
  ceb = 'Cebuano',
  co = 'Corsican',
  cs = 'Czech',
  cy = 'Welsh',
  da = 'Danish',
  de = 'German',
  el = 'Greek',
  en = 'English',
  eo = 'Esperanto',
  es = 'Spanish',
  et = 'Estonian',
  eu = 'Basque',
  fa = 'Persian',
  fi = 'Finnish',
  fil = 'Filipino',
  fr = 'French',
  fy = 'Frisian',
  ga = 'Irish',
  gd = 'Scots',
  gl = 'Galician',
  gu = 'Gujarati',
  ha = 'Hausa',
  hi = 'Hindi',
  hmn = 'Hmong',
  hr = 'Croatian',
  ht = 'Haitian',
  hu = 'Hungarian',
  hy = 'Armenian',
  id = 'Indonesian',
  ig = 'Igbo',
  is = 'Icelandic',
  it = 'Italian',
  iw = 'Hebrew',
  ja = 'Japanese',
  jv = 'Javanese',
  ka = 'Georgian',
  kk = 'Kazakh',
  km = 'Khmer',
  kn = 'Kannada',
  ko = 'Korean (South Korea)',
  ku = 'Kurdish',
  ky = 'Kyrgyz',
  la = 'Latin',
  lb = 'Luxembourgish',
  lt = 'Lithuanian',
  lv = 'Latvian',
  mg = 'Malagasy',
  mi = 'Maori',
  mk = 'Macedonian',
  ml = 'Malayalam',
  mn = 'Mongolian',
  mr = 'Marathi',
  ms = 'Malay',
  mt = 'Maltese',
  ne = 'Nepali',
  nl = 'Dutch',
  no = 'Norwegian',
  ny = 'Chichewa',
  or = 'Odia',
  pa = 'Punjabi',
  pl = 'Polish',
  ps = 'Pashto',
  pt = 'Portuguese (European)',
  ro = 'Romanian',
  ru = 'Russian',
  rw = 'Kinyarwanda',
  sd = 'Sindhi',
  si = 'Sinhala',
  sk = 'Slovak',
  sl = 'Slovenian',
  sm = 'Samoan',
  sn = 'Shona',
  so = 'Somali',
  sq = 'Albanian',
  sr = 'Serbian',
  st = 'Sesotho',
  su = 'Sundanese',
  sv = 'Swedish',
  sw = 'Swahili',
  ta = 'Tamil',
  te = 'Telugu',
  tg = 'Tajik',
  th = 'Thai',
  tk = 'Turkmen',
  tr = 'Turkish',
  tt = 'Tatar',
  ug = 'Uyghur',
  uk = 'Ukrainian',
  ur = 'Urdu',
  uz = 'Uzbek',
  vi = 'Vietnamese',
  xh = 'Xhosa',
  yi = 'Yiddish',
  yo = 'Yoruba',
  zu = 'Zulu',
}
