<div class="console_modal rating_dialog conversation_interactions">
    <div class="modal-header">
        <section class="head_top">
            <h4 class="modal-title">Conversation {{ sessionId }}</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
                <i class="fal fa-times" aria-hidden="true"></i>
            </button>
        </section>
        <section class="head_bottom">
            <div class="row align-items-end">
                <div class="col-12">
                    <ul class="conversation_summary">
                        <li>{{ interactions[0]?.timestamp | date: 'dd/MM/yyyy - H:mm' }}</li>
                        <li>{{ interactions.length }} Request</li>
                        <li>{{ fallbackCount }} No Match</li>
                    </ul>
                </div>
            </div>
        </section>
    </div>

    <div class="modal-body">
        <div class="body_content">
            <ng-template [spinnerLoader]="interactionsSubscription.closed"></ng-template>
            <div class="row conversation_interaction" *ngFor="let interaction of interactions; let i = index">
                <div class="col-12">
                    <chat-interaction [interaction]="interaction" [agentBotType]="agentBotType" [uneditableView]="true"></chat-interaction>
                </div>
            </div>
        </div>
    </div>
</div>
