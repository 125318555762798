import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { JourneyService } from '@core';
import { BLUE } from '@shared';
import Chart from 'chart.js/auto';
import { DashboardService } from '../../../services/dashboard.service';



@Component({
  selector: 'sessions-card',
  templateUrl: './sessions-card.component.html',
  styleUrls: ['./sessions-card.component.scss']
})
export class SessionsCardComponent implements OnInit {

  subscriptions: object = {};
  currentDate = new Date();
  conversationChart: any;
  chartDataset: any[] = [];
  chartLabels: any[] = [];

  constructor(private dashboardservice: DashboardService, private journeyService: JourneyService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.subscriptions['JourneySubscription'] = this.journeyService.journey$.subscribe(() => {
      this.getStatistics();
    });
  }

  ngOnDestroy() {
    if (this.conversationChart instanceof Chart) this.conversationChart.destroy();

    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getStatistics() {
    const params = {
      startDate: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 7, 0, 0, 0).getTime(),
      endDate: this.currentDate.setHours(23, 59, 59, 999),
      tags: null,
    };
    this.getConversationsStatistics(params);
  }

  getConversationsStatistics(params: any) {
    this.subscriptions['ConversationsStatistics'] = this.dashboardservice.getConversationsStatistics(params.startDate, params.endDate, params.tags).subscribe((response: any) => {
      this.proceddSessionsChartData(response.multiObject.line.chart);
    });
  }

  proceddSessionsChartData(data) {
    this.chartLabels = [];
    this.chartDataset = [
      {
        data: [],
        label: 'Sessions ',
        color: BLUE,
      },
    ];
    data.forEach((value: any) => {
      this.chartLabels.push(this.datePipe.transform(value.currentDate, 'd MMM yyyy'));
      this.chartDataset[0].data.push(value.value);
    });
  }
}
