
import { Environment } from '../environment.interface';

export const TEST_ENVIRONMENT: Environment = {
    production: false,
    BASE_PATH: 'https://dev.tellya.ai',
    API: {
        orchestrator_analysis: '/analysis/api/v1',
        orchestrator_analysis_v2: '/analysis/api/v2',
        orchestrator_configurator: '/configurator/api/v2',
        orchestrator_rasa_model: '/rasa-model-server',
        orchestrator_chat: '/chat/api/v2',
        channel_whatsapp: '/bot/whatsapp',
        channel_vivocha: '/bot/vivocha',
        channel_liveperson: '/bot/liveperson',
        channel_timrcc: '/bot/timrcc',
        channel_facebook: '/bot/facebook',
        channel_google: '/bot/google',
        channel_teams: '/bot/teams',
        channel_telegram: '/bot/telegram'
    },
    websocket_path: 'wss://dev.tellya.ai/configurator/api/v2/stomp',
    AUTHENTICATION: {
        keycloak_issuer: 'https://dev.tellya.ai/access/realms/tellya',
        keycloak_client: 'tellya-frontend'
    }
};
