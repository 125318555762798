import { Component, Input, TemplateRef } from '@angular/core';
import { NotificationModalOptions } from '../../models/notification-modal-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent {
  @Input() options: NotificationModalOptions;

  errorCollapsed: boolean = true;

  constructor(private activeModal: NgbActiveModal) { }

  abort(): void {
    this.activeModal.close(false);
  }

  confirm(): void {
    this.activeModal.close(true);
  }

  isTemplate() { return this.options.message instanceof TemplateRef; }

}
