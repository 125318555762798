<div class="console_modal">
    <div class="modal-header">
        <h4 class="modal-title">Status info</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>

    <div class="modal-body">
        <div class="body_content">
            <div class="row mx-0 mb-3">
                <div class="col-auto">
                    {{ statuses.name }}
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-6 mb-3" *ngFor="let item of statuses.components | keyvalue">
                    <component-status-card [name]="item.key" [status]="item.value.status"></component-status-card>
                </div>
            </div>
            <div class="row mx-0 mb-3" *ngIf="(statuses.agents| keyvalue)?.length > 0">
                <div class="col-auto">
                    Agents
                </div>
            </div>
            <div class="row mx-0" *ngIf="(statuses.agents| keyvalue)?.length > 0">
                <div class="col-12 mb-3" *ngFor="let item of statuses.agents | keyvalue">
                    <liveperson-agent-status-card [name]="item.key" [status]="item.value.status" (changeStatus)="changeLivepersonAgentStatus(item.key, $event)" (removeAgent)="removeAgent(item.key)"></liveperson-agent-status-card>
                </div>
            </div>
        </div>
    </div>
</div>
