<div class="box_wrapper">
    <div class="dashboard_chunk">
        <h6 class="chunk_title">All sessions</h6>
        <div class="row overview_head flex-grow-1">
            <div class="col-12 head_sidebar">
                <article class="summary_card">
                    <figure class="card_image"><i class="far fa-comments" aria-hidden="true"></i></figure>
                    <div class="card_body">
                        <span class="body_title">Total sessions</span>
                        <h4 class="body_data" *ngIf="statistics$ | async as stats; else loading">{{ stats?.total | analyticalFormatter: { type: 'number', format: '1.0-2' } }}</h4>
                    </div>
                </article>
                <article class="summary_card">
                    <figure class="card_image"><i class="far fa-clock" aria-hidden="true"></i></figure>
                    <div class="card_body">
                        <span class="body_title">Average duration</span>
                        <h4 class="body_data" *ngIf="statistics$ | async as stats; else loading">{{ stats?.avgDuration | analyticalFormatter: { type: 'date', format: "m'm' ss's'" } }}</h4>
                    </div>
                </article>
                <article class="summary_card">
                    <figure class="card_image"><i class="far fa-envelope" aria-hidden="true"></i></figure>
                    <div class="card_body">
                        <span class="body_title">Average messages</span>
                        <h4 class="body_data" *ngIf="statistics$ | async as stats; else loading">{{ stats?.avgMessages | analyticalFormatter: { type: 'number', format: '1.0-2' } }}</h4>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <ng-template [spinnerLoader]="false" [size]="'sm'"></ng-template>
</ng-template>
