
<section class="progress_steps">
    <article class="step_body" *ngFor="let step of phases.steps; let i = index" [ngClass]="{'current': phases.current === i}">
        <div class="body_info">
            <button class="step_bubble" (click)="changeStep(i)">
                <i class="fas fa-check" aria-hidden="true" *ngIf="stepsForm.at(i).valid && step.submitted; else stepNumber"></i>
                <ng-template #stepNumber>
                    <strong>{{i + 1}}</strong>
                </ng-template>
            </button>
            <span class="step_name">{{step.name}}</span>
        </div>
    </article>
</section>
