import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto/auto.esm';

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit, OnDestroy {
  private _legend = false;
  public get legend() {
    return this._legend;
  }
  @Input() public set legend(value) {
    this._legend = value;
    if (this.chartRef) {
      this.updateChart();
    }
  }

  private _labels: string[];
  public get labels(): string[] {
    return this._labels;
  }
  @Input() public set labels(value: string[]) {
    this._labels = value;
    if (this.chartRef) {
      this.updateChart();
    }
  }

  _dataset: { data: number[]; label: string; color: string }[];
  get dataset(): { data: number[]; label: string; color: string }[] {
    return this._dataset;
  }
  @Input() set dataset(dataset: { data: number[]; label: string; color: string }[]) {
    this._dataset = dataset;
    if (this.chartRef) {
      this.updateChart();
    }
  }

  @ViewChild('lineChartWrapper') lineChart: ElementRef;
  chartRef;

  ngAfterViewInit() {
    const config = {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.dataset.map(({ data, label, color }) => ({ data, label, borderColor: color, backgroundColor: color, borderJoinStyle: 'round' })),
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          y: {
            min: 0,
            suggestedMax: 10,
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          legend: {
            display: this.legend,
            position: 'bottom',
            align: 'end',
            labels: {
              padding: 20,
              color: '#000000',
              font: {
                size: 11,
                family: 'Poppins, sans-serif',
              },
              boxWidth: 8,
              usePointStyle: true,
              pointStyle: 'circle',
              textAlign: 'left',
            },
          },

          tooltip: {
            displayColors: true,
            backgroundColor: 'rgba(255, 255, 255)',
            bodyAlign: 'left',
            bodyColor: 'rgba(0, 0, 0)',
            titleColor: 'rgba(0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0.8)',
            borderWidth: 0.5,
            usePointStyle: true,
            pointStyle: 'circle',
          },
        },
      },
    };
    this.chartRef = new Chart(this.lineChart.nativeElement, config);
  }

  ngOnDestroy() {
    if (this.chartRef instanceof Chart) this.chartRef.destroy();
  }
  updateChart() {
    this.chartRef.options.plugins.legend.display = this.legend;
    this.chartRef.data.labels = this.labels;
    this.chartRef.data.datasets = this.dataset.map(({ data, label, color }) => ({ data, label, borderColor: color, backgroundColor: color, borderJoinStyle: 'round' }));
    this.chartRef.update();
  }
}
