import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  template: '',
})
export class LoginComponent {
  constructor(private oauthService: OAuthService) {
    this.oauthService.initCodeFlow();
  }
}
