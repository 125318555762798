import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleRestrictionGuard } from '@core';
import { HealthStatusComponent } from './components/health-status/health-status.component';
import { LogsComponent } from './components/logs/logs.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';



const routes: Routes = [
    {
        path: '',
        component: MonitoringComponent,
        canActivate: [RoleRestrictionGuard],
        data: {
          roleRestriction: ['monitoring.status.view', 'monitoring.logs.view']
        },
        children: [
          { path: '', redirectTo: '/monitoring/status', pathMatch: 'full' },
          {
            path: 'status',
            component: HealthStatusComponent,
          },
          {
            path: 'logs',
            component: LogsComponent,
            data: {
              roleRestriction: ['monitoring.logs.view'],
            },
          },
        ],
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MonitoringRoutingModule { }