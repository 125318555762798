<div class="console_modal notification_modal">
    <div class="modal-header">
        <h4 class="modal-title">Inactivity alert</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>

    <ng-container *ngIf="sessionExpired === false; else sessionExpiredTemplate">
        <div class="modal-body">
            <div class="body_content">
                <div class="row">
                    <div class="col-2 content_type">
                        <i class="fal fa-exclamation-triangle fa-4x text-warning" aria-hidden="true"></i>
                    </div>
                    <div class="col">You will be logged out due to inactivity in {{ remainingTime | date: 'mm:ss' }}. Interact with the platform to stay signed in.</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn brand_default" (click)="close()">OK</button>
        </div>
    </ng-container>

    <ng-template #sessionExpiredTemplate>
        <div class="modal-body">
            <div class="body_content">
                <div class="row">
                    <div class="col-2 content_type">
                        <i class="fal fa-times-circle fa-4x text-danger" aria-hidden="true"></i>
                    </div>
                    <div class="col">You have been logged out because of inactivity. Please login again.</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn brand_default" (click)="close()">Go to login</button>
        </div>
    </ng-template>
</div>
