import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ToneOfVoiceSurveyComponent } from './components/tone-of-voice-survey/tone-of-voice-survey.component';

const routes: Routes = [{ path: '', component: ToneOfVoiceSurveyComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ToneOfVoiceRoutingModule {}
